import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import TextArea from "./TextArea";
import Input from "./Input";
import Recaptcha from "react-google-invisible-recaptcha";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;

    const errors = {};

    error.details.forEach(item => {
      errors[item.path[0]] = item.message;
    });

    return errors;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();

    this.setState({ errors: errors || {} });

    if (errors) return;

    this.recaptcha.reset();

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data });
  };

  handleCheckboxToggle = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.checked;

    this.setState({ data });
  };

  handleCaptchaTick = () => {
    this.recaptcha.execute();
  };

  onCaptchaResolved = name => {
    const data = { ...this.state.data };

    data[name] = this.recaptcha.getResponse() ? true : false;

    this.setState({ data });
  };

  renderInput(name, placeholder, type = "text") {
    return (
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={this.state.data[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderTextArea(name, placeholder) {
    return (
      <TextArea
        name={name}
        placeholder={placeholder}
        value={this.state.data[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderCheckbox(name, label) {
    return (
      <label className="form__check-box">
        <input
          type="checkbox"
          name={name}
          checked={this.state.data[name]}
          onChange={this.handleCheckboxToggle}
        />
        <span>{label}</span>
      </label>
    );
  }

  renderPrivacyStatement(name) {
    return (
      <label className="form__check-box">
        <input
          type="checkbox"
          name={name}
          checked={this.state.data[name]}
          onChange={this.handleCheckboxToggle}
        />
        <span>
          I have read and understand the{" "}
          <Link to="/privacy">privacy statement</Link> and agree with its
          content.
        </span>
      </label>
    );
  }

  renderSelectBox(name, placeholder, data, valueProp, textProp) {
    return (
      <div className="form__select">
        <select
          name={name}
          value={this.state.data[name]}
          onChange={this.handleChange}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {data.map(item => (
            <option key={item[valueProp]} value={item[valueProp]}>
              {item[textProp]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderGoogleCaptcha(name, label) {
    return (
      <React.Fragment>
        <Recaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey="6LefS7YUAAAAACj_bP32V9M4eQFfVbKlT9RAUDQc"
          onResolved={() => this.onCaptchaResolved(name)}
        />
        <label className="form__check-box">
          <input
            type="checkbox"
            name={name}
            checked={this.state.data[name]}
            disabled={this.state.data[name]}
            onChange={this.handleCaptchaTick}
          />
          <span>{label}</span>
        </label>
      </React.Fragment>
    );
  }
}

export default Form;
