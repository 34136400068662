import React, { Component } from "react";
import PageTitle from "../commons/PageTitle";

class DisclaimerPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - Disclaimer";
  }
  render() {
    return (
      <div className="disclaimer-page">
        <div className="container">
          <PageTitle text="Disclaimer" />
          <p className="paragraph paragraph--2">
            The information and materials on the LeasePLUS Group Websites
            (including but not limited to the software, calculators, tools,
            logos and names) is derived from sources believed to be accurate and
            current as of the date of posting to the Website.
          </p>
          <p className="paragraph paragraph--2">
            LeasePLUS Group Pty Ltd (LeasePLUS Group), and its officers, agents, employees and contractors
            do not (1) give any representation or warranty as to the
            reliability, accuracy or completeness of the information and
            materials on this Website; nor (2) warrant that your access to the
            Website will be uninterrupted or error-free.
          </p>
          <p className="paragraph paragraph--2">
            LeasePLUS Group nor its employees shall not be liable in any way for
            any loss or damage, however arising, whether in negligence or
            otherwise, out of or in connection with the information or omissions
            on the Website (including any linked site).
          </p>
          <p className="paragraph paragraph--2">
            <b>Indemnity</b>
            <br></br>
            You agree to indemnify and hold harmless LeasePLUS Group and its
            employees and keep them indemnified, against all loss, actions,
            proceedings, costs, expenses (including legal fees), claims and
            damages arising from: (1) any breach by you of the Terms and
            Conditions of Use; or (2) reliance by you on any information
            obtained through this Website; or (3) your access and or use of the
            Site or any information downloaded from it; or (4) reliance upon, or
            use of, any information by any person accessing it through your
            Website.
          </p>
        </div>
      </div>
    );
  }
}

export default DisclaimerPage;
