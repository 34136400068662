import React from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "./../../img/logos/LPG-logo.png";

const Nav = ({ isOpened, onOpen, location }) => {
  return (
    <nav className="nav">
      <div className="nav__container">
        <Link to="/" className="nav__lpg-logo">
          <img src={logo} alt="LeasePLUS Group Logo" />
        </Link>
        {location.pathname.toLowerCase().includes("company") ? (
          ""
        ) : (
          <button
            style={{ visibility: isOpened ? "hidden" : "visible" }}
            className="nav__btn-open-menu"
            onMouseEnter={onOpen}
            onClick={onOpen}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
        )}
      </div>
    </nav>
  );
};

export default withRouter(Nav);
