import React from "react";

const TextArea = ({ name, placeholder, value, onChange }) => {
  return (
    <textarea
      className="form__control"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
    ></textarea>
  );
};

export default TextArea;
