import React from "react";

const DefinitionTable = () => {
  return (
    <div className="definition-table">
      <div className="definition-table__header">Definitions</div>
      <div className="item">
        <div className="item__title">ACN</div>
        <div className="item__definition">Australian Company Number</div>
      </div>
      <div className="item">
        <div className="item__title">ABN</div>
        <div className="item__definition">Australian Business Number</div>
      </div>
      <div className="item">
        <div className="item__title">APP</div>
        <div className="item__definition">
          Australian Privacy Principles Act
        </div>
      </div>
      <div className="item">
        <div className="item__title">Cookie</div>
        <div className="item__definition">
          small piece of data sent from a website and stored on the user's
          computer by the user's web browser while the user is browsing.
        </div>
      </div>
      <div className="item">
        <div className="item__title">CRB</div>
        <div className="item__definition">Credit Reporting Body</div>
      </div>
      <div className="item">
        <div className="item__title">CRI</div>
        <div className="item__definition">Credit Reporting Index</div>
      </div>
      <div className="item">
        <div className="item__title">Cth</div>
        <div className="item__definition">Commonwealth</div>
      </div>
      <div className="item">
        <div className="item__title">GDPR</div>
        <div className="item__definition">
          means the General Data Protection Regulation, being Regulation
          2016/679 of the European Parliament and of the Council
        </div>
      </div>
      <div className="item">
        <div className="item__title">IP Address</div>
        <div className="item__definition">Internet Protocol Address</div>
      </div>
      <div className="item">
        <div className="item__title">PPSA</div>
        <div className="item__definition">Personal Property Securities Act</div>
      </div>
      
    </div>
  );
};

export default DefinitionTable;
