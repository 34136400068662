import React, { Component } from "react";
import SocialConnection from "../commons/SocialConnection";
import ContactForm from "../commons/ContactForm";

class ContactUsPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - Contact Us";
  }
  render() {
    return (
      <div className="contact-us-page">
        <SocialConnection />
        <ContactForm />
      </div>
    );
  }
}

export default ContactUsPage;
