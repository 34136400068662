import React from "react";

const PageTitle = ({ text }) => {
  return (
    <div className="page-title">
      <h2 className="heading heading--2">{text}</h2>
    </div>
  );
};

export default PageTitle;
