import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import HomeHeader from "./headers/HomeHeader";
import NavMenu from "./NavMenu";
import Nav from "./Nav";
import AboutUsHeader from "./headers/AboutUsHeader";
import PrivacyHeader from "./headers/PrivacyHeader";
import LegalHeader from "./headers/LegalHeader";
import CreditReportingPolicyHeader from "./headers/CreditReportingPolicyHeader";
import DisclaimerHeader from "./headers/DisclaimerHeader";
import ContactUsHeader from "./headers/ContactUsHeader";
import OurBlogHeader from "./headers/OurBlogHeader";

class Header extends Component {
  state = {
    isOpened: false,
  };

  handleOpenMenu = () => {
    const isOpened = true;

    this.setState({ isOpened });
  };

  handleCloseMenu = () => {
    const isOpened = false;

    this.setState({ isOpened });
  };

  render() {
    return (
      <header className="header">
        <Nav isOpened={this.state.isOpened} onOpen={this.handleOpenMenu}></Nav>
        <NavMenu
          isOpened={this.state.isOpened}
          onClose={this.handleCloseMenu}
        ></NavMenu>
        <div className="header__container">
          <Switch>
            <Route path="/our-blog" component={OurBlogHeader} />
            <Route path="/contact-us" component={ContactUsHeader} />
            <Route path="/about-us" component={AboutUsHeader} />
                    <Route path="/privacy" component={PrivacyHeader} />
                    <Route path="/credit-report" component={CreditReportingPolicyHeader} />
                    <Route path="/legal" component={LegalHeader} />
            <Route path="/company/:name" exact></Route>
                    <Route path="/disclaimer" component={DisclaimerHeader} />
            <Route paht="/not-found" component={HomeHeader} />
            <Route path="/" exact component={HomeHeader} />
          </Switch>
        </div>
      </header>
    );
  }
}

export default Header;
