import React from "react";

const CreditReportingPolicyHeader = (props) => {
    return (
        <div className="header__about-us">
            <div className="header__about-us__title">
                <h1 className="heading heading--1">Credit Reporting Policy</h1>
            </div>

        </div>
    );
};

export default CreditReportingPolicyHeader;