import React, { Component } from "react";
import CompanyList from "../commons/CompanyList";
import Safecode from "../../img/logos/SafeCode-logo.png"
import LP from "../../img/logos/LP-logo.png"
import SPP from "../../img/logos/SPP-logo.png"
import LPG from "../../img/logos/LPG-logo.svg"

class HomePage extends Component {
    componentDidMount() {
        document.title = "LeasePLUS Group #BornToInnovate";
    }

    render() {
        return (
            //<div className="home-page">
            //  <div className="container home-page__container">
            //    <CompanyList />
            //  </div>
            //</div>
            <div className="safecode-gray-landingpage">
                <div className="safecode-gray-landingpage-box">
                    <div className="safecode-gray-landingpage-links">
                        <img src={LPG} />
                    </div>
                    <div className="safecode-gray-landingpage-images">
                        <a href="https://leaseplus.com.au/" target="_blank">
                            <img className="safecode-gray-landingpage-image1" src={LP} />
                        </a>
                        <a href="https://salarypackagingplus.com.au/" target="_blank">
                            <img className="safecode-gray-landingpage-image2" src={SPP} />
                        </a>
                        <a href="https://safecode.com.au/" target="_blank">
                            <img className="safecode-gray-landingpage-image3" src={Safecode} />
                        </a>
                    </div>
                    <div className="safecode-gray-landingpage-footer">
                        <a href="https://leaseplus.com.au/" target="_blank">Novated Leasing</a>
                        <a href="https://salarypackagingplus.com.au/" target="_blank">Salary Packaging</a>
                        <a href="https://safecode.com.au/" target="_blank">Technology Solutions</a>
                    </div>
                    <div><hr style={{ borderTop: "0.1px solid #2E2E2E" }} /></div>
                    <div className="safecode-gray-landingpage-email"><a href="mailto:enquiries@leaseplusgroup.com.au">enquiries@leaseplusgroup.com.au</a></div>
                </div>
            </div>

        );
    }
}

export default HomePage;
