import React, { Component } from "react";
import PageTitle from "./../commons/PageTitle";

class CreditReportPage extends Component {
  state = {};

  componentDidMount() {
    document.title = "LeasePLUS Group - Credit Report Policy";
  }

  render() {
    return (
      <div className="credit-report-page">
        <div className="container">
          <PageTitle text="Credit reporting policy" />
          <p className="paragraph paragraph--2">
            This policy applies in addition to our Privacy Policy and sets out
            details of our handling of personal information obtained from credit
            reporting bodies and certain other consumer credit-related personal
            information described below. This applies in Australia and primarily
            in connection with our consumer credit products such as credit cards
            and loans, and in relation to guarantee arrangements for commercial
            credit. It does not apply to our handling of commercial credit
            information for purposes unrelated to consumer credit.
          </p>
          <p className="paragraph paragraph--2">
            <b>The information we collect and hold</b>
            <br></br>
            The types of credit-related personal information ("credit-related
            information") we collect may include:
          </p>
          <div className="paragraph paragraph--2">
            <ul style={{ paddingLeft: "2rem" }}>
              <li>
                permitted identification information such as names, date of
                birth, sex, 3 most recent addresses, employer and driver's
                licence number;
              </li>
              <li>
                the fact that any consumer or commercial credit has been applied
                for and the amount and type of credit;
              </li>
              <li>details of your current and previous credit providers;</li>
              <li>
                credit limits and certain terms and conditions relating to
                credit arrangements;
              </li>
              <li>start/end dates of particular credit arrangements;</li>
              <li>
                records of previous requests made by credit providers to credit
                reporting bodies for information about you in connection with
                consumer or commercial credit applications, guarantees and
                securitisation arrangements;
              </li>
              <li>repayment history;</li>
              <li>
                'default information' (in addition to and without limiting
                repayment history information), i.e. payments overdue for more
                than 60 days in specified circumstances;
              </li>
              <li>
                in relation to those overdue payments, advice about new payment
                arrangements or that those payments are no longer overdue;
              </li>
              <li>
                information about adverse court judgements, personal insolvency
                records and publicly-available information related to your
                credit worthiness;
              </li>
              <li>
                in specified circumstances that, in the opinion of
                LeasePLUS Group Pty Ltd (LeasePLUS Group) or another credit provider, there has been a
                serious credit infringement (e.g. fraud);
              </li>
              <li>
                information we derive from the above information (e.g. our own
                credit scores, ratings and assessments).
              </li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            This information may include information about your arrangements
            with other credit providers as well as with us.
          </p>
          <p className="paragraph paragraph--2">
            We keep credit-related information in physical and electronic
            records, both at our own premises and with the assistance of our
            service providers.
          </p>
          <p className="paragraph paragraph--2">
            We collect this information from you, your account activity and from
            third parties including public sources, our related companies,
            referrers, brokers, agents, your adviser(s) and our service
            providers including credit reporting bodies and information service
            providers.
          </p>
          <p className="paragraph paragraph--2">
            <b>Purposes for handling that information</b>
            <br></br>
            We collect, hold, use and disclose credit-related information to
            assess and process an application by you for consumer or commercial
            credit; manage your credit and related funding arrangements; assess
            certain claims including hardship; assess an application by you to
            be a guarantor; review your credit on a periodic basis or in
            connection with changes (e.g. credit limit); collect overdue
            payments; create assessments and ratings of your credit worthiness;
            administer and manage our products and services including
            monitoring, auditing, and evaluating those products and services;
            model and test data; communicate with you; work with our service
            providers, credit managers, mortgage insurers, funders (including
            those providing securitisation services) and related companies; and
            deal with any complaints or enquiries. We may also use and disclose
            credit-related information in connection with acquisitions, mergers
            or changes in control of our business or assets.
          </p>
          <p className="paragraph paragraph--2">
            We may from time to time also use credit-related information to
            inform you of investment opportunities or to provide information
            about products and services which we expect may be of interest to
            you. However, we do respect your right to ask us not to do this.
          </p>
          <p className="paragraph paragraph--2">
            Credit reporting bodies may use credit-related information to
            maintain information about individuals and provide that information
            to credit providers for their credit assessments. We may also report
            to a credit reporting body where an individual fails to meet their
            payment obligations in relation to consumer credit or commits a
            serious credit infringement. In some circumstances, subject to legal
            restrictions, the parties with whom we share credit-related
            information may operate outside of Australia (this includes
            locations in the countries listed in the Appendix to our Privacy
            Policy). Where this occurs, we take steps to protect information
            against misuse or loss.
          </p>
          <p className="paragraph paragraph--2">
            <b>How you can access or correct your information</b>
            <br></br>
            You can contact us as set out below to request access to or
            correction of your credit-related information. In normal
            circumstances we will give you full access or make the requested
            corrections to your information. However, there may be some legal or
            administrative reasons to deny these requests. If your request is
            denied, we will provide you with the reason why. Where we decide not
            to make a requested correction to your credit-related information
            and you disagree, you may ask us to make a note of your requested
            correction with the information.
          </p>
          <p className="paragraph paragraph--2">
            <b>Credit reporting bodies</b>
            <br></br>
            We may exchange credit-related information with the following credit
            reporting bodies:
          </p>
          <div className="paragraph paragraph--2">
            <ul style={{ paddingLeft: "2rem" }}>
              <li>
                            Equifax, PO Box 964 North Sydney 2059, <a
                                href="https://www.mycreditfile.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                www.mycreditfile.com.au
            </a>,
                1383 32
              </li>
              <li>
                            Illion, 479 St. Kilda Road Melbourne 3004,
                <a
                                href="https://www.illion.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                www.illion.com.au
            </a>, <a
                                href="mailto:PAC.austral@illion.com.au"
                                rel="noopener noreferrer"
                            >
                                PAC.austral@illion.com.au
            </a>, 1300 734 806
              </li>
              <li>
                            Experian, GPO Box 1969, North Sydney NSW 2060,
                <a
                                href="https://www.experian.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                www.experian.com.au
            </a>, 1300 784 134
              </li>
              
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            You can contact those credit reporting bodies or visit their
            websites to see their policies on the management of credit-related
            information, including details of how to access your credit-related
            information they hold. From 12 March 2014, you will also have the
            right to request credit reporting bodies not to:
          </p>
          <p className="paragraph paragraph--2">
            use your credit-related information to determine your eligibility to
            receive direct marketing from credit providers; and 20use or
            disclose your credit-related information, if you have been or are
            likely to be a victim of fraud.
          </p>
          
          <p className="paragraph paragraph--2">
            <b>Contact us</b>
            <br></br>
            If you have any questions regarding this policy or our handling of
            credit-related information, please direct your enquiry to 
            <a
                        href="mailto:enquiries@leaseplusgroup.com.au"
                        rel="noopener noreferrer"
                    >
                        enquiries@leaseplusgroup.com.au
            </a> or contact us by phone. Please mark
            communications to the attention of our Privacy Officer. Where you
            contact us seeking resolution of your privacy concerns, we will
            respond to let you know who will be handling your matter and when
            you can expect a further response. If your concerns are not resolved
            to your satisfaction, you may have rights to escalate your complaint
            to an external dispute resolution scheme. We can provide further
            details of the appropriate scheme for you on request as these may
            vary by jurisdiction or product. You may also contact the Office of
            the Australian Information Commissioner as follows:
          </p>
          <p className="paragraph paragraph--2">Phone: 1300 363 992</p>
          <p className="paragraph paragraph--2">
            GPO Box 5218<br></br>
            Sydney, NSW, 2001
          </p>
         
        </div>
      </div>
    );
  }
}

export default CreditReportPage;
