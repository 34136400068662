import React from "react";

const CrbTable = () => {
  return (
    <div className="detail-table detail-table--sm">
      <table>
        <thead>
          <tr>
            <th>Name {"&"} Credit Reporting Policy</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Illion</b> <br />
              <a
                href="http://www.illion.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.illion.com.au
              </a>
              <br />
              <b>Ph</b>: 1300 734 806 <br />
              PO Box 7405 <br />
              St Kilda Rd, Melbourne, VIC
            </td>
          </tr>
          <tr>
            <td>
              <b>Experian Australia</b> <br />
              <a
                href="http://www.experian.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.experian.com.au
              </a>
              <br />
              <b>Ph</b>: 1300 783 684 <br />
              GPO Box 1969 <br />
              North Sydney NSW 2060
            </td>
          </tr>
          <tr>
            <td>
              <b>Equifax Pty Ltd</b> <br />
              <a
                href="http://www.mycreditfile.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mycreditfile.com.au
              </a>
              <br />
              <b>Ph</b>: 1300 762 207 <br />
              GPO Box 964 <br />
              North Sydney NSW 2059
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
  );
};

export default CrbTable;
