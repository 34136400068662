import React from "react";

import wallpaper from "../../img/logos/LPG_White_1920x1080.jpg";

const AWISA2020Page = () => {
  return (
    <img
      src={wallpaper}
      alt="LPG logo"
      style={{ width: "100%", height: "100vh", position: "fixed" }}
    />
  );
};

export default AWISA2020Page;
