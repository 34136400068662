import React, { Component } from "react";
import PageTitle from "../commons/PageTitle";
import DefinitionTable from "../commons/DefinitionTable";
import AbnTable from "../commons/AbnTable";
import WebsiteTable from "../commons/WebsiteTable";
import CrbTable from "../commons/CrbTable";

class PrivacyPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - Privacy";
  }
  render() {
    return (
      <div className="privacy-page">
        <div className="container">
          <PageTitle text="Privacy" />
          <DefinitionTable></DefinitionTable>
          
          <p className="paragraph paragraph--2">
            This Privacy Statement applies to business conducted by LeasePLUS
            Group Pty Ltd (LeasePLUS Group) (ABN 73 158 960 617) and all associated companies
            (collectively referred to as the LeasePLUS Group of companies; “us”,
            “we” or “our”) and sets out how we manage Personal Information
            provided to or collected by us.
          </p>
          <p className="paragraph paragraph--2">
            LeasePLUS Group of companies understands that your privacy is
            important. We are committed to protecting your Personal Information
            (as defined in the Privacy Act).
          </p>
          <p className="paragraph paragraph--2">
            We are bound by the Australia Privacy Principles (APPs) contained in
            the Privacy Act 1988 and associated legislation (as amended from
            time to time).
          </p>
          <p className="paragraph paragraph--2">
            This Privacy Statement applies to your use (as your user agreement
            with us) of our website’s, as listed below, and to the collection of
            any Personal Information by us in the course of our business.
          </p>
          
          <p className="paragraph paragraph--2">
            You grant us permission to collect and disclose your Personal
            Information in accordance with this Privacy Policy and those
            policies of our agents, brokers, third parties, banks or funders,
            Credit Reporting Bodies or any other party related to the provision
            of our services.
          </p>
          <p className="paragraph paragraph--2">
            We may change or update this policy and the information contained in
            it from time to time without notice and all amendments will take
            effect immediately. Continued use of our websites and services
            constitutes your agreement to any amended user agreement. We provide
            this information in utmost good faith.
          </p>
          <p className="paragraph paragraph--2">
            We may also have obligations in relation to your Personal
            Information under a range of other legislation including for example
            (but not limited to), the Anti-Money Laundering and Counter
            Terrorism Financing Act 2006 (Cth), Electronic Transactions Act 1999
            (Cth), National Consumer Credit Protection Act 2009 (Cth), and
            Credit Reporting Code (2014). We may also collect Personal
            Information under the Personal Property Securities Act 2012 (Cth)
            (PPSA).
          </p>
          <div className="paragraph paragraph--2">
            <b>
              What kind of Personal Information is collected and how is it
              collected?
            </b>
            <br />
            The type of information we may collect and hold includes (but is not
            limited to) Personal Information, including information about:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>
                Names, addresses (mail and email), previous addresses, phone
                numbers;
              </li>
              <li>DOB, gender, number of dependents, relationship status;</li>
              <li>Signatures;</li>
              <li>
                Banking and other relevant financial information including TFN,
                salary/income, assets, utility or phone contract and payment
                details;
              </li>
              <li>
                Statement of assets, liabilities and other financial information
                and any supporting documentation;
              </li>
              <li>
                Identification proof or information including drivers licence;
              </li>
              <li>ACN/ABN details;</li>
              <li>Application for credit forms;</li>
              <li>
                Credit provided by other credit providers including any credit
                you may have applied for or guaranteed;
              </li>
              <li>Credit checks, credit history, credit scores or ratings;</li>
              <li>
                Personal solvency records or relevant court proceedings or
                information;
              </li>
              <li>Overdue payments;</li>
              <li>Discharge of payments form previous credit providers; and</li>
              <li>
                Any other Personal Information deemed relevant or that we are
                legally required to collect so that we can provide you with our
                services.
              </li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            We will collect Personal Information in a variety of ways including
            by way of phone and text message, forms completed and provided to us
            via email, mail, our websites, fax, mobile Apps, subscriptions,
            services or newsletters.
          </p>
          <p className="paragraph paragraph--2">
            We may collect information directly from you, for example, when you
            have a salary packaging agreement with us, complete an application
            form for one of our products or services, deal with us over the
            telephone or in person or send us a message by text, or via our
            Apps, or a letter. If you used our websites to register for further
            information or to subscribe to one of our services/newsletters
            service, we will also collect information from you.
          </p>
          <p className="paragraph paragraph--2">
            If you used our websites to register for further information or to
            subscribe to one of our services/newsletters service, we will also
            collect information from you.
          </p>
          <p className="paragraph paragraph--2">
            In some circumstances we may be provided with Personal Information
            about you from a third party such as your employer, credit provider,
            family member, and credit reporting agencies, our business alliance
            partners, your agents or third-party brokers. If required by law, we
            will obtain your consent to collect information from third party
            sources.
          </p>
          <div className="paragraph paragraph--2">
            <b>Information collected from our websites</b>
            <br />
            When you visit our websites, our web servers/Internet Service
            Providers makes a record of your visit through your browser and logs
            the following information for statistical purposes only:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>
                the number of visitors to the website (gathered via IP address
                logs);
              </li>
              <li>
                your server's IP address (a number which is unique to the
                computer through which you have accessed the Internet);
              </li>
              <li>the date and time of your visit to the site;</li>
              <li>the pages you accessed and documents downloaded; and</li>
              <li>the type of browser and operating system you are using.</li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            The above statistical data is anonymous and is used to evaluate our
            website performance and to measure the interest in the various areas
            of our site. We will not try to identify you or your browsing
            activities except, where we are compelled to do so by law (for
            example: pursuant to a valid search warrant to inspect the service
            provider’s logs) or if your use is causing technical concerns for
            our site which may need to be resolved.
          </p>
          <p className="paragraph paragraph--2">
            We will only record your e-mail address if you send us a message.
            Your e-mail address will only be used for the purpose for which you
            have provided it and it will not be added to a mailing list or used
            for any other purpose without your consent.
          </p>
          <div className="paragraph paragraph--2">
            <b>Information in relation to Credit Reporting Bodies</b>
            <br />
            When you ask us to provide our services or for other similar or
            related reasons, we may be required to check your credit worthiness
            or provide your information to a nominated third party. In order to
            do this for you, we may collect Personal Information about you
            directly from you, through an agent or a third party, through
            publicly available sources, a related company, referrer, broker,
            agent, advisor, service provider, information service provider, or
            through our banks or various funders. We may be required to give
            that Personal Information to one or more credit reporting bodies
            (CRBs). The contact details of the <b>CRBs</b> we may use is
            outlined below. In addition to the Personal Information already
            stated above, the types of credit-related personal information{" "}
            <b>(CRI)</b> we collect may include:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>
                if you have applied for any consumer or commercial credit and
                the amount and type of credit;
              </li>
              <li>details of current and previous credit providers;</li>
              <li>
                credit limits and certain terms and conditions relating to
                credit arrangements;
              </li>
              <li>start/end dates of particular credit arrangements;</li>
              <li>
                records of previous requests made by credit providers to credit
                reporting bodies for information about you in connection with
                consumer or commercial credit applications, guarantees and
                securitisation arrangements;
              </li>
              <li>repayment history;</li>
              <li>default information;</li>
              <li>repayment history information;</li>
              <li>
                overdue payments for more than 60 days in specified
                circumstances;
              </li>
              <li>
                in relation to those overdue payments, advice about new payment
                arrangements or that those payments are no longer overdue;
              </li>
              <li>
                in specified circumstances that, in our opinion or that of
                another credit provider, there has been a serious credit
                infringement (e.g. fraud);
              </li>
              <li>
                information we derive from the above information such as our own
                credit ratings or scores or assessments.
              </li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            This Personal Information may include information about your
            arrangements with other credit providers as well as with us.
          </p>
          <p className="paragraph paragraph--2">
            We may keep your credit-related information in both physical and
            electronic records, both at our own offices and also with the
            assistance of our service providers.
          </p>
          <p className="paragraph paragraph--2">
            We collect, hold, use and disclose CRI to help us assess and process
            an application by you for credit, to help manage your credit and
            related funding or payment arrangements, to assess any claims you
            may have, to assess an application by you to be a guarantor, to
            provide a review of your credit from time to time or if there are
            any changes to an adjustment to a credit limit, collection of
            payments including overdue payments, creating necessary assessments
            and ratings of credit worthiness, administration and management of
            all our services and products, working with our agents, referrers,
            service providers, credit managers, insurers, banks or funders and
            other related companies, and so that we may manage complaints or
            assist you with any enquiries. We may also be required to use and
            disclose CRI if we acquire, sell or merge with another business.
          </p>
          <p className="paragraph paragraph--2">
            CRBs use CRI about you to maintain information and to provide that
            information to various providers of credit including banks or
            funders or us, our agents or service providers and so that they may
            make their own credit assessments. We may also report to a CRB if
            you fail to meet payment obligations in relation to consumer credit
            or if there is an issue regarding credit infringement. The CRB we
            may use include but not limited to (and are subject to change):
          </p>
          <CrbTable></CrbTable>
          <p className="paragraph paragraph--2">
            By law, you may contact a CRB and we suggest you visit their
            websites (listed above) to review their relevant policies and so you
            can understand how a CRB may manage your CRI. The links above also
            provide you with details of how to access your credit-related
            information that a CRB may hold. You are entitled to request CRBs
            not to use your CRI to determine your eligibility to receive direct
            marketing from credit providers. Also, you may request a CRB not to
            use or disclose your CRI if you have been or are likely to be a
            victim of fraud.
          </p>
          <p className="paragraph paragraph--2">
            If we may assist you with any questions regarding your CRI, please
            refer to the contact details below.
          </p>
          <p className="paragraph paragraph--2">
            <b>How we use, disclose and hold Personal Information</b>
            <br />
            We collect Personal Information about you only where it is necessary
            for our business so that we may provide you with the services
            requested and you will usually be told the purposes for which we
            collect that information when it is collected. Generally, the
            collection of your Personal Information will be necessary for us to
            provide a service to you and where applicable, other purposes that
            you would reasonably expect or to which you have consented.
          </p>
          <div className="paragraph paragraph--2">
            We may disclose the Personal Information to a credit reporting body,
            bank, financier, your employer, anyone you authorise us to disclose
            information to and anyone to whom we are required to disclose the
            information by law. We may also share the Personal Information with
            other companies you may reasonably expect us and so that you may be
            provided with services that are of interest to you. For example,
            vehicle suppliers, fuel companies, insurance companies or other
            similar companies. You can ask us not to do this at any time through
            our easy to follow opt-outs. We will not use or disclose Personal
            Information collected about you other than for a purpose:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>made known to you;</li>
              <li>you would reasonably expect,</li>
              <li>required or permitted by or under any law; or</li>
              <li>a purpose otherwise authorised by you.</li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            <b>Cookies</b>
            <br />A 'cookie' is a small text file that allows our server to
            identify and interact more effectively with your computer. Cookies
            can be either "persistent" or "session" based. Persistent cookies
            are stored on your computer, contain an expiration date, and may be
            used to track your browsing behaviour upon return to the issuing
            Website. Session cookies are short-lived, are used only during a
            browsing session, and expire when you quit your browser.
          </p>
          <p className="paragraph paragraph--2">
            Our websites may also store cookies on your web browser in order to
            improve service for you on your subsequent visits to this website.
            To track usage more generally, we may use third parties to collect
            statistical data via cookies.
          </p>
          <p className="paragraph paragraph--2">
            <b>Management and security of Personal Information</b>
            <br />
            We respect the confidentiality of your Personal Information and the
            privacy of individuals. We have in place steps to protect the
            Personal Information held from misuse, interference and loss,
            unauthorised access, modification or disclosure by use of various
            methods including locked storage of paper records and password
            access rights to computerised records. However, in relation to
            Personal Information provided via our websites, no data transmission
            over the Internet can be guaranteed as fully secure and accordingly,
            we cannot guarantee or warrant the security of any information you
            send to us using our on-line forms or products. You submit
            information over the Internet at your own risk.
          </p>
          <p className="paragraph paragraph--2">
            We will retain your Personal Information for as long as we require
            it for our purposes or as required under law. When we no longer
            require it, we will destroy or de-identify the Personal Information.
            We take all necessary steps to destroy or permanently de-identify
            Personal Information which is no longer needed for the purposes
            described in this Privacy Statement.
          </p>
          <p className="paragraph paragraph--2">
            We may disclose Personal Information to overseas recipients, for
            instance, when store Personal Information with ‘cloud’ service
            providers which may be situated outside Australia which may include
            the USA, UK, Philippines, India, Singapore and other countries from
            time to time. However, we will not send Personal Information about
            an individual outside Australia without:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>
                obtaining the consent of the individual (in some cases this
                consent will be implied); or
              </li>
              <li>
                otherwise complying with the Privacy Act or other applicable
                privacy legislation.
              </li>
            </ul>
          </p>
          <p className="paragraph paragraph--2">
            <b>Mandatory data breach notification</b> <br />
            LeasePLUS Group is committed to doing all that it can to protect the
            Personal Information of its valued customers, clients, suppliers,
            agents, brokers, referrers and any other person or entity from whom
            we collect data or your Personal Information. As the internet is
            inherently insecure no matter how many protection measures we may
            take, should there be a data breach, we may notify you as soon as
            practicable in compliance with the law. In the case of what is known
            under the law as an ‘eligible data breach’, we may notify you of
            what has occurred, what Personal Information is involved, what the
            implications may be, what you can do, and what we will do in the
            specific circumstances.
          </p>
          <p className="paragraph paragraph--2">
            <b>Tax File Number</b> <br />
            We do not use your tax file number to identify you. The purpose for
            which you provide your tax file number to us is to facilitate tax
            and superannuation administration only. We will only use and
            disclose your tax file number for the purposes required by law.
          </p>
          <p className="paragraph paragraph--2">
            <b>Access and correction of Personal Information</b> <br />
            Under the Privacy Act an individual has the right to obtain access
            to any Personal Information which about them and to advise of any
            perceived inaccuracy. There are some exceptions to this right set
            out in the Privacy Act.
          </p>
          <p className="paragraph paragraph--2">
            To make a request to access or update any Personal Information we
            hold about you, please contact our Privacy Officer at{" "}
            <a
              href="mailto:enquiries@leaseplusgroup.com.au"
              rel="noopener noreferrer"
            >
              enquiries@leaseplusgroup.com.au
            </a>{" "}
            or on 1300 13 13 16.
          </p>
          <p className="paragraph paragraph--2">
            We will require you to verify your identity and specify what
            information you require. We may charge a fee to cover the cost of
            verifying your application and locating, retrieving, reviewing and
            copying any material requested. If we agree to correct the Personal
            Information, we will do it as soon as practicable and if there is a
            delay, we will advise you in writing.
          </p>
          <p className="paragraph paragraph--2">
            <b>Enquiries and complaints</b> <br />
            If you would like further information about the way we manage the
            Personal Information we hold, or wish to complain that you believe
            we have breached the Australian Privacy Principles, please contact
            our Privacy Officer at{" "}
            <a
              href="mailto:enquiries@leaseplusgroup.com.au"
              rel="noopener noreferrer"
            >
              enquiries@leaseplusgroup.com.au
            </a>{" "}
            or on 1300 13 13 16 and we will investigate any complaint. We will
            notify you of a decision in relation to your complaint as soon as
            practicable after it has been made.
          </p>
          <p className="paragraph paragraph--2">
            You may also obtain further information regarding compliance with
            the Privacy Act from the Office of the Federal Privacy
            Commissioner’s website at{" "}
            <a
              href="https://www.oaic.gov.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.oaic.gov.au
            </a>
            .
          </p>
          <p className="paragraph paragraph--2">
            The information and materials on our websites (including but not
            limited to the software, calculators, tools, logos and names) is
            derived from sources believed to be accurate and current as of the
            date of posting.
          </p>
          <p className="paragraph paragraph--2">
            Our companies, its officers, agents, employees and contractors do
            not (1) give any representation or warranty as to the reliability,
            accuracy or completeness of the information and materials on its
            websites; or (2) warrant that your access to the websites will be
            uninterrupted or error-free.
          </p>
          <p className="paragraph paragraph--2">
            Our companies and our employees will not be liable in any way for
            any loss or damage, however arising, whether in negligence or
            otherwise, out of or in connection with the information or omissions
            on the websites (including any linked site) or any App.
          </p>
          <p className="paragraph paragraph--2">
            <b>European Union</b>
            <br />
            If you are a resident of the European Union for the purposes of the
            GDPR, then in addition to what is set out above, the following
            applies to you.
          </p>
          <p className="paragraph paragraph--2">
            LEASEPLUS GROUP is a data controller and processor for the purposes
            of the GDPR and by your consenting to this Privacy Statement
            LEASEPLUS GROUP can processes your Personal Data in accordance with
            this Privacy Statement.
          </p>
          <p className="paragraph paragraph--2">
            In providing its services to you, LEASEPLUS GROUP may make use of
            several automated processes using your Personal Data and your
            activity on our Site as tracked by us, in order to provide more
            tailored and relevant services to you such as providing relevant
            products and services to you through email communication, and on the
            Site or within our Apps.
          </p>
          <p className="paragraph paragraph--2">
            In addition to your rights set out above, you may:
            <ul style={{ paddingLeft: "3.5rem", marginTop: "1rem" }}>
              <li>
                update or rectify any of the Personal Data that we hold about
                you, in the manner described in the “Access and correction of
                Personal Information” paragraph.
              </li>
              <li>
                withdraw your consent to LEASEPLUS GROUP’s use of your Personal
                Information as described in this Privacy Statement described in
                the “Access and correction of Personal Information” paragraph.
              </li>
              <li>request that LEASEPLUS GROUP</li>
            </ul>
            <ul style={{ paddingLeft: "7rem" }}>
              <li>
                provides you with a copy of the Personal Data LEASEPLUS GROUP
                holds about you in a portable and machine-readable form; or
              </li>
              <li>share your Personal Data with a nominated third party;</li>
              <li>
                you may request that from us by contacting us using any of the
                methods set out in the “Enquiries and complaints” paragraph
                above.
              </li>
            </ul>
          </p>
          <p className="paragraph paragraph--2">
            Should you have any concerns in relation to LEASEPLUS GROUP’s
            collection and/or processing of your Personal Data, then in addition
            to the process set out in the “Enquiries and complaints and the
            LEASEPLUS GROUP website“ set out above (including the right to
            complain to the Office of the Australian Information Commissioner),
            you have the right to complain to a supervisory authority (within
            the meaning of the GDPR).
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPage;
