import { FETCH_COMPANY, FETCH_COMPANIES } from "../actions/types";
import _ from "lodash";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return { ...state, ..._.mapKeys(action.payload, "name") };
    case FETCH_COMPANY:
      if (!action.payload) {
        return {};
      }
      return { ...state, [action.payload.name]: action.payload };
    default:
      return state;
  }
};
