import React from "react";

import banner from "./../../../img/miscs/BornToInnovate.png";

const HomeHeader = () => {
  return (
    <div className="header__home">
      <div className="header__home__img-banner">
        <img src={banner} alt="#BornTo Inovate" />
      </div>
      <p className="paragraph paragraph--1">
        LeasePLUS Group is committed to <b>Innovate</b>, <b>Develop</b> and{" "}
        <b>Deliver</b> market leading solutions to <br /> its valuable customers
        in different industry segments.
      </p>
    </div>
  );
};

export default HomeHeader;
