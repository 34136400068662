import React from "react";

const LoadingPanel = () => {
  return (
    <div className="loading-panel">
      <div className="loading-panel__container">
        <div className="loading-panel__spinner-box">
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
        <div className="loading-panel__text-box">Loading</div>
      </div>
    </div>
  );
};

export default LoadingPanel;
