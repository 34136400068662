import React, { Component } from "react";
import PageTitle from "../commons/PageTitle";

class LegalPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - Legal";
  }
  render() {
    return (
      <div className="legal-page">
        <div className="container">
          <PageTitle text="Legal" />
          <p className="paragraph paragraph--2">
            There are a number of legal matters you should be aware of, and
            agree to, before you go any further on this site. These matters include:
          </p>
          <p className="paragraph paragraph--2">
            1. the LeasePLUS Group Website Privacy Statement; <br></br>
            2. the Terms and Conditions of Use to which you agree when you visit
            this site; and <br></br>
            3. the basis on which LeasePLUS Group, and any of its related
            entities, are excluded from any liability relating to the use of
            this site. <br></br>
            See: Disclaimer & Terms and conditions of use (below).
          </p>
          <p className="paragraph paragraph--2">
            <b>Terms and conditions of use</b>
            <br></br>
            Acknowledgement and acceptance of conditions
            <br></br>
            <a href="http://www.leaseplusgroup.com.au">http://www.leaseplusgroup.com.au</a> (the Website) is owned and operated
            by LeasePLUS Group Pty Ltd (LeasePLUS Group) ABN 73 158 960 617 under the registered domain
            name of <a href="http://www.leaseplusgroup.com.au">www.leaseplusgroup.com.au</a>.
          </p>
          <p className="paragraph paragraph--2">
            Reading of this material implies that you agree with and understand
            the Legal Information contained on this site and are bound by the
            terms and conditions of access to this site and all disclaimers
            found on this Website (general conditions). You are deemed to accept
            these general conditions of use each and every time you access this
            Website in any way
          </p>
          <p className="paragraph paragraph--2">
            LeasePLUS Group reserves the right to amend the general conditions
            at any time. Accordingly, you should periodically refer to the
            conditions contained in this document and elsewhere on the Website.
          </p>
          <p className="paragraph paragraph--2">
            <b>Use of the website</b>
            <br></br>
            You may view the Website and save an electronic copy, or print out a
            copy of parts of this Website solely for your own personal use,
            information, research or study. The express written authority of
            LeasePLUS Group must be obtained before the Website or any of the
            information or software contained on it is used for any commercial
            purpose.
          </p>
          <p className="paragraph paragraph--2">
            You must not use the Website or any of the information or software
            contained on it in any way or for any purpose which:
          </p>
          <div className="divaragraph paragraph--2">
            <ul style={{ paddingLeft: "2rem" }}>
              <li>is unlawful; or</li>
              <li>interferes with any legal rights of LeasePLUS Group; or</li>
              <li>is prohibited by the general conditions.</li>
            </ul>
          </div>
          <p className="paragraph paragraph--2">
            <b>Copyright</b>
            <br></br>
            The materials displayed on this Website, including all information,
            text, materials, graphics, software, tools, advertisements, names,
            logos and trade marks on the Website (information and software) are
            protected by copyright, trade mark and other intellectual property
            laws unless otherwise expressly indicated on this Website.
          </p>
          <p className="paragraph paragraph--2">
            You may download, display, print and reproduce the information on
            this Website, in unaltered form only, for your personal,
            non-commercial use or use within your organisation. Apart from any
            use as permitted under the Copyright Act 1968, all other rights are
            reserved.
          </p>
          <p className="paragraph paragraph--2">
            <b>Not professional advice</b>
            <br></br>
            The information on this Website is provided by way of background
            only. It is made available to you on the understanding that
            LeasePLUS Group is not rendering any legal, financial or any other
            advice.
          </p>
          <p className="paragraph paragraph--2">
            You should obtain professional financial advice tailored to your
            specific circumstances and needs prior to making any financial
            decisions.
          </p>
          <p className="paragraph paragraph--2">
            <b>Currency of information</b> <br></br>
            LeasePLUS Group has taken reasonable care creating this Website and
            will endeavour to ensure that it contains accurate and current
            information.
          </p>
          <p className="paragraph paragraph--2">
            However, it is recommended that visitors to the Website exercise
            their own skill and care with respect to the use of information
            found on it. Before relying on the material found on this Website,
            users are asked to consider its accuracy, currency, completeness and
            relevance to their purposes.
          </p>
          <p className="paragraph paragraph--2">
            <b>Access to the website</b>
            <br></br>
            LeasePLUS Group accepts no liability for any interference with, or
            damage to, a visitor's computer system, software or data occurring
            in connection with or relating to this Website or its use. LeasePLUS
            Group does not warrant that your access to this Website will be
            uninterrupted or error free.
          </p>
          <p className="paragraph paragraph--2">
            Links to other Internet sites are provided for the user's
            convenience and do not constitute LeasePLUS Group's endorsement of
            the information at those sites, or any associated person,
            organisation, product or service and is not to be construed in any
            way as an endorsement by LeasePLUS Group.
          </p>
          <p className="paragraph paragraph--2">
            <b>Governing law</b>
            <br></br>
            These general conditions and any disputes arising out of the use of
            this Website are governed by, construed and enforced in accordance
            with the laws of Victoria.
          </p>
          <p className="paragraph paragraph--2">
            <b>Termination</b>
            <br></br>
            LeasePLUS Group retains the right to terminate your access to this
            Website at any time for any reason and without any warning.
          </p>
        </div>
      </div>
    );
  }
}

export default LegalPage;
