import React from "react";

const PrivacyHeader = (props) => {
  return (
    <div className="header__about-us">
      <div className="header__about-us__title">
              <h1 className="heading heading--1">Privacy</h1>
      </div>
      
    </div>
  );
};

export default PrivacyHeader;
