import * as companyAPI from "../services/CompanyService";
import { FETCH_COMPANY, FETCH_COMPANIES } from "./types";

export const fetchCompanies = () => (dispatch) => {
  const response = companyAPI.getCompanies();
  dispatch({
    type: FETCH_COMPANIES,
    payload: response,
  });
};

export const fetchCompany = (name) => (dispatch) => {
  const response = companyAPI.getCompany(name);
  dispatch({
    type: FETCH_COMPANY,
    payload: response,
  });
};
