import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Header from "./components/layouts/Header";
import HomePage from "./components/pages/HomePage";
import Footer from "./components/layouts/Footer";
import DisclaimerPage from "./components/pages/DisclaimerPage";
import LegalPage from "./components/pages/LegalPage";
import PrivacyPage from "./components/pages/PrivacyPage";
import AboutUsPage from "./components/pages/AboutUsPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import NotFound from "./components/pages/NotFound";
import ScrollToTop from "./components/commons/ScrollToTop";
import CreditReportPage from "./components/pages/CreditReportPage";
import OurBlogPage from "./components/pages/OurBlogPage";
import AWISA2020Page from "./components/pages/AWISA2020Page";
import CompanyPage from "./components/pages/CompanyPage";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToTop>
          {/*{this.props.location.pathname.toLowerCase() !== "/awisa2020" ? (*/}
          {/*  <Header />*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
          <Switch>
            <Route path="/AWISA2020" component={AWISA2020Page}></Route>
            <Route path="/our-blog" component={OurBlogPage}></Route>
            <Route path="/contact-us" component={ContactUsPage}></Route>
            <Route path="/about-us" component={AboutUsPage}></Route>
            <Route path="/privacy" component={PrivacyPage}></Route>
            <Route path="/credit-report" component={CreditReportPage}></Route>
            <Route path="/legal" component={LegalPage}></Route>
            <Route path="/disclaimer" component={DisclaimerPage}></Route>
            <Route path="/company/:name" exact component={CompanyPage}></Route>
            <Route path="/not-found" component={NotFound}></Route>
            <Route path="/" exact component={HomePage}></Route>
            <Redirect to="/not-found" />
          </Switch>
          {/*{this.props.location.pathname.toLowerCase() !== "/awisa2020" ? (*/}
          {/*  <Footer />*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </ScrollToTop>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
