import { connect } from "react-redux";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchCompanies } from "../../actions";

class CompanyList extends Component {
  componentDidMount() {
    this.props.fetchCompanies();
  }

  render() {
    return (
      <ul className="company-list">
        {this.props.companies.map((company) => (
          <li key={company.name} className="company-list__company">
            <Link to={`/company/${company.name}`}>
              <img
                src={company.logo}
                alt={`${company.name} Logo`}
                className="company-list__company__logo-blue"
              />
              <img
                src={company.mainLogo}
                alt={`${company.name} Logo`}
                className="company-list__company__logo-main"
              />
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: Object.values(state.companies),
  };
};

export default connect(mapStateToProps, { fetchCompanies })(CompanyList);
