import React, { Component } from "react";

class AboutUsPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - About Us";
  }
  render() {
    return (
      <div className="about-us-page">
        <div className="about-us-page__story">
          <div className="container">
            <div>
              <h1 className="heading heading--1">Our Story</h1>
            </div>
            <p className="paragraph paragraph--2">
              Since our humble beginnings in 2001, innovation has been at the
              heart of what we do. As our reputation has grown, we have been
              empowered to create opportunities and extend our unparalleled
              services to support customers in a variety of industries.
            </p>
            <p className="paragraph paragraph--2">
              First opening the doors with a small novated leasing company,
              we swiftly gained recognition, progressing into
              salary packaging. As our relationships
              grew, we recognised the opportunity to offer additional solutions
              to our customers.
            </p>
            <p className="paragraph paragraph--2">
              We knew early in our journey that technology would set us apart
              from our competitors and through the process of developing our
              leasing and salary packaging platforms internally, we saw the
              evolution of our software and IT solution business.
            </p>
            
            <p className="paragraph paragraph--2">
              Never straying from our founding principles, our commitment to
              growing our employees, customers and improving our technology and
              services, is what sets us apart.
            </p>
          </div>
        </div>
        <div className="section section--1 about-us-page__our-mission">
          <div className="container">
            <div className="paragraph paragraph--2">
              <h1 className="heading heading--1">Our Mission</h1>
              <br></br>
              <br></br>
              <h3 className="heading heading--3">
                You are at the core of our purpose and our mission is to:
              </h3>
            </div>
            <div className="row about-us-page__our-mission__detail-table">
              <div className="col-1-3">
                <div className="paragraph paragraph--2">
                  <h3 className="heading heading--3">
                    <b>Innovate:</b>
                  </h3>
                  <br></br>
                  Innovate in the pursuit of continuous advancement to sustain
                  the growth of our staff, customers, products, services,
                  businesses, partners, industries and environment.
                </div>
              </div>
              <div className="col-1-3">
                <div className="paragraph paragraph--2">
                  <h3 className="heading heading--3">
                    <b>Develop:</b>
                  </h3>
                  <br></br>
                  Develop ideas into action and action into results. This the
                  creation of an ecosystem where knowledge is shared and valued
                  within our community to produce benefits that are passed onto
                  our valued customers.
                </div>
              </div>
              <div className="col-1-3">
                <div className="paragraph paragraph--2">
                  <h3 className="heading heading--3">
                    <b>Deliver:</b>
                  </h3>
                  <br></br>
                  Deliver exceptional products, services and benefits to our
                  valued customers by connecting them to solutions they asked
                  for or didn’t know they needed.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-page__our-values">
          <div className="container">
            <div className="paragraph paragraph--2">
              <h1 className="heading heading--1">Values</h1>
            </div>
            <div className="about-us-page__our-values__detail-table">
              <div className="about-us-page__our-values__detail-table__col about-us-page__our-values__detail-table__col--tp">
                <p className="paragraph paragraph--3">
                  The daily work of LeasePLUS Group is underpinned by four
                  strong values that explain the motivation of staff to succeed
                  in these competitive markets. These values are:
                </p>
              </div>
              <div className="about-us-page__our-values__detail-table__col">
                <div
                  style={{ height: "13rem" }}
                  className="paragraph paragraph--2 u-p-height-reset"
                >
                  <h4 className="heading heading--4">Quality:</h4>
                  <br></br>
                  To provide meaningful information, to ensure customer
                  satisfaction in each engagement.
                </div>
                <div className="paragraph paragraph--2">
                  <h4 className="heading heading--4">Respect:</h4>
                  <br></br>
                  To value the individual, to encourage ideas and opinions, and
                  to foster a balanced lifestyle.
                </div>
              </div>
              <div className="about-us-page__our-values__detail-table__col">
                <div
                  style={{ height: "13rem" }}
                  className="paragraph paragraph--2 u-p-height-reset"
                >
                  <h4 className="heading heading--4">Growth:</h4>
                  <br></br>
                  To commit to personal and professional growth by building
                  working relationships with colleagues and customers, and in
                  the setting of personal goals.
                </div>
                <div className="paragraph paragraph--2">
                  <h4 className="heading heading--4">Courage:</h4>
                  <br></br>
                  To be willing to work with change in the pursuit of product
                  and service improvement and excellence.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsPage;
