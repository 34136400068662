import React from "react";

const Input = ({ name, placeholder, ...rest }) => {
  return (
    <input
      className="form__control"
      placeholder={placeholder}
      name={name}
      {...rest}
    />
  );
};

export default Input;
