import mediaImg from "../img/our-blogs/icon-press-release.png";
import ceoImg from "../img/our-blogs/icon-ceo.png";

const blogs = [{
        image: mediaImg,
        title: "Media Releases",
        link: "https://blog.leaseplusgroup.com.au/category/media-releases/"
    },
    {
        image: ceoImg,
        title: "CEO's blog",
        link: "https://blog.leaseplusgroup.com.au/category/ceos-blog/"
    }
];

export function getBlogs() {
    return blogs;
}