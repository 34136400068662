import lpLogoBlue from "./../img/logos/NovatedLeasing_Blue.png";
import sppLogoBlue from "./../img/logos/SalaryPackaging_Blue.png";
import ausmachBlue from "./../img/logos/Manufacturing_Blue.png";
import safecodeBlue from "./../img/logos/Software_Blue.png";
import mssLogoBlue from "./../img/logos/Logo_MySharedServices_blue.png";

import lpLogo from "./../img/logos/NovatedLeasing_Orange.png";
import sppLogo from "./../img/logos/SalaryPackaging_Orange.png";
import safecode from "./../img/logos/Software_Orange.png";
import ausmach from "./../img/logos/Manufacturing_Orange.png";
import mssLogo from "./../img/logos/Logo_MySharedServices.png";

import CarLeaseBrand from "../img/LP-brands/logo-CarLeasingOptions.png";
import CarsHubBrand from "../img/LP-brands/logo-CarsHub.png";
import LPFinanceBrand from "../img/LP-brands/logo-LeasePLUS-Financial-Services.png";
import ManufinBrand from "../img/LP-brands/logo-Mnufin.png";
import MFBBrand from "../img/LP-brands/logo-MyFundingBox.png";
import MISBrand from "../img/LP-brands/logo-MyInsuranceSolutions.png";
import MLPBrand from "../img/LP-brands/logo-MyLoansPlus.png";
import MMPBrand from "../img/LP-brands/logo-MyMortgagePlus.png";
import MVPBrand from "../img/LP-brands/logo-MyVehiclePlus.png";
import SLOBrand from "../img/LP-brands/logo-SalaryLeasingOptions.png";

import MCPBrand from "../img/SPP-brands/logo-MyCardPlus.png";
import MCPRBrand from "../img/SPP-brands/logo-MyCardPlus-Rewards.png";
import SOBrand from "../img/SPP-brands/logo-SalaryOptions.png";

import TopSolidBrand from "../img/SafeCode-brands/logo-TopSolid.png";
import PaletteCADBrand from "../img/SafeCode-brands/logo-PaletteCAD.png";
import COBUS from "../img/SafeCode-brands/logo-COBUS.png";
import MSPBrand from "../img/SafeCode-brands/logo-mySalPack.png";
import MLBrand from "../img/SafeCode-brands/logo-MyLease.png";
import MFPBrand from "../img/SafeCode-brands/logo-MyFactory-Pro.png";

const companies = [
  {
    name: "LeasePLUS",
    logo: lpLogoBlue,
    mainLogo: lpLogo,
        link: "mailto:enquiries@leaseplusgroup.com.au",
    heading: "Australia’s leading supplier of Novated Leasing solutions",
    description: [
      "Since 2001, LeasePLUS brand has set high-quality standards for the supply and administration of novated leasing services.",
      "The LeasePLUS systems, processes and service are considered the benchmark within the industry and we lead the way in in offering the best in market novated lease services, nationwide.",
      "Other services include operating leasing, motor vehicle insurance, vehicle sourcing, equipment funding and other financial services.",
    ],
    brands: [
      MLPBrand,
      CarsHubBrand,
      MVPBrand,
      LPFinanceBrand,
      SLOBrand,
      ManufinBrand,
    ],
        btnText: "Email for information",
  },
  {
    name: "SalaryPackagingPlus",
    logo: sppLogoBlue,
    mainLogo: sppLogo,
      link: "mailto:enquiries@leaseplusgroup.com.au",
    heading: "Salary packaging services with high level of care and competency",
    description: [
      "We've made significant investment in technology to create advanced proprietary systems that deliver best in class salary packaging solutions to clients and employers throughout Australia.",
      "Since 2004, we have been proudly servicing the public health, not-for-profit and corporate/government organisations that priorities quality service over shareholder profits. This is what makes us the most sought-after service provider in salary packaging.",
    ],
    brands: [MCPBrand, MCPRBrand, SOBrand],
      btnText: "Email for information",
  },
  // {
  //   name: "Manufin",
  //   logo: manufinBlue,
  //   mainLogo: manufin,
  //   link: "https://www.manufin.com.au/"
  // },
  // {
  //   name: "MyCardPlus",
  //   logo: mcpBlue,
  //   mainLogo: mcp,
  //   link: "https://www.mycardplus.com.au/",
  // },
  {
    name: "SafeCode",
    logo: safecodeBlue,
    mainLogo: safecode,
      link: "mailto:enquiries@leaseplusgroup.com.au",
    heading: "Innovation: Design | Develop | Deliver",
    description: [
      "We have R&D and innovation in our DNA, providing end-to-end system solutions in the areas of financial services and manufacturing. Our Salary Packaging and Leasing systems are developed from ground up using the latest technologies and are currently supporting some of the largest suppliers of Novated Leasing and Salary Packaging in Australia.",
      "We are also committed to supporting the Australian manufacturing industry by providing innovative software solutions that are often used in high quality manufacturing hubs in Europe such as Germany, France and Italy.",
    ],
    brands: [
      TopSolidBrand,
      PaletteCADBrand,
      COBUS,
      MSPBrand,
      MLBrand,
      MFPBrand,
    ],
      btnText: "Email for information",
  },
  {
    name: "Ausmach",
    logo: ausmachBlue,
    mainLogo: ausmach,
      link: "mailto:enquiries@leaseplusgroup.com.au",
    heading:
      "The leading supplier of woodworking & metal machinery in Australia and New Zealand",
    description: [
      "We offer a range of high-quality woodworking, ACM, aluminium, metal, and extraction machinery to the Australian and New Zealand markets. Innovative technologies sets us apart from other suppliers in the machinery importation industry, along with highly trained service technicians to provide advice and support to clients looking to improve their production output.",
    ],
    brands: [],
      btnText: "Email for information",
  },
  //{
  //  name: "MySharedServices",
  //  logo: mssLogoBlue,
  //  mainLogo: mssLogo,
  //  link: "https://www.mysharedservices.com.au/",
  //  heading: "Supporting the LeasePLUS Group of companies ",
  //  description: [
  //    "MySharesServices offers accounting, finance, human resource, legal and property management services to the LeasePLUS Group of companies.",
  //  ],
  //  brands: [],
  //  btnText: "Call 1300 131 316",
  //},
];

export function getCompanies() {
  return companies;
}

export const getCompany = (name) => {
  return companies.find((c) => c.name === name);
};
