import React from "react";
import Joi from "joi-browser";
import Form from "./Form";
import Axios from "axios";
import LoadingPanel from "./LoadingPanel";
import { dialogBox } from "./../../services/DialogBox";

class ContactForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      comment: "",
      hasRead: false,
      isNotRobot: false
    },
    errors: {}
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First name"),
    lastName: Joi.string()
      .required()
      .label("Last name"),
    emailAddress: Joi.string()
      .email({ minDomainAtoms: 2 })
      .label("Email address"),
    phoneNumber: Joi.string()
      .required()
      .label("Phone number"),
    comment: Joi.string().allow(""),
    hasRead: Joi.boolean()
      .valid(true)
      .error(() => {
        return { message: "Please read and agree with our privacy statement" };
      }),
    isNotRobot: Joi.boolean()
      .valid(true)
      .error(() => {
        return { message: "Captcha is required." };
      })
  };

  doSubmit = async () => {
    //Call the server
    try {
      this.setState({ isLoading: true });

      await Axios.post("/Api/Enquiry/SendEnquiry", this.state.data);

      this.setState({ isLoading: false });

      dialogBox.info(
        "Submit Complete",
        "Your message has been sent successfully. We will contact you shortly.",
        this.resetForm
      );
    } catch (ex) {
      console.log(ex.response);
    }
  };

  resetForm = () => {
    const data = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      comment: "",
      hasRead: false,
      isNotRobot: false
    };

    this.setState({ data });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <LoadingPanel />}
        <form onSubmit={this.handleSubmit}>
          <div className="contact-form">
            <div className="container">
              <div className="contact-form__title">
                <h5 className="heading heading--5">Send us your details</h5>
              </div>
              <p className="paragraph paragraph--2">
                Fields marked with * are mandatory.
              </p>
              <div className="form contact-form__form">
                <div className="form__group">
                  <div className="row">
                    <div className="col-1-2">
                      {this.renderInput("firstName", "First name*")}
                    </div>
                    <div className="col-1-2">
                      {this.renderInput("lastName", "Last name*")}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1-2">
                      {this.renderInput(
                        "emailAddress",
                        "Email address*",
                        "email"
                      )}
                    </div>
                    <div className="col-1-2">
                      {this.renderInput("phoneNumber", "Phone number*", "tel")}
                    </div>
                  </div>
                  <div className="row">
                    {this.renderTextArea(
                      "comment",
                      "What would you like to know? / Ask us a question!"
                    )}
                  </div>
                </div>
                <div className="form__group">
                  {this.renderPrivacyStatement("hasRead")}
                </div>
                <div className="form__group">
                  {this.renderGoogleCaptcha("isNotRobot", "I am not a robot")}
                </div>
                <div style={{ textAlign: "center" }} className="form__group">
                  <button className="btn btn--1" disabled={this.validate()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default ContactForm;
