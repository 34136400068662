import React from "react";

const ContactUsHeader = () => {
  return (
    <div className="header__contact-us">
      <div className="header__contact-us__title">
        <h1 className="heading heading--1">Contact Us</h1>
      </div>
      <p className="paragraph paragraph--1">
        "We are small enough to care and large enough to deliver"
      </p>
      <p className="paragraph paragraph--3">
        <b>Aytunc Tezay</b> - Chairman and Group Managing Director
      </p>
      <p className="paragraph paragraph--1" style={{ marginTop: "5rem" }}>
        Let us help you get the right solution.
      </p>
    </div>
  );
};

export default ContactUsHeader;
