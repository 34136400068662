import React from "react";
import { NavLink } from "react-router-dom";
import SocialConnection from "./../commons/SocialConnection";

const NavMenu = ({ isOpened, onClose }) => {
  return (
    <div
      className={isOpened ? "nav-menu active" : "nav-menu"}
      onMouseLeave={onClose}
    >
      <div className="nav-menu__container">
        <button className="nav-menu__btn-close-menu" onClick={onClose}></button>
        <ul className="nav-menu__list">
          <li>
            <NavLink to="/" exact onClick={onClose}>
              Our Group
            </NavLink>
          </li>
          <li>
            <NavLink to="/about-us" onClick={onClose}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-us" onClick={onClose}>
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/our-blog" onClick={onClose}>
              Blog
            </NavLink>
          </li>
          <li>
            <a
              href="http://blog.leaseplusgroup.com.au/careers/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Careers
            </a>
          </li>
        </ul>
        <div className="nav-menu__social-connection">
          <SocialConnection />
          <ul className="policy__list">
            <li>
              <NavLink to="/disclaimer" onClick={onClose}>
                Disclaimer
              </NavLink>
            </li>
            <li>
              <NavLink to="/legal" onClick={onClose}>
                Legal
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy" onClick={onClose}>
                Privacy
              </NavLink>
            </li>
          </ul>
          <div className="policy__time">
            © LeasePLUS Group {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
