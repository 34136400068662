import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCompany } from "../../actions";

class CompanyPage extends React.Component {
  componentDidMount() {
    this.props.fetchCompany(this.props.match.params.name);
  }

  renderMSSBtns = () => {
    return (
      <div className="company__btns company__btns--mss">
        <div className="company__btns--mss--text">Email to:</div>
        <div className="form__group company__btn">
          <a
            href="mailto:finance@mysharedservices.com.au" 
            className="btn btn--1"
          >
            Financial Services
          </a>
        </div>
        <div className="form__group company__btn">
          <a href="mailto:hr@mysharedservices.com.au" className="btn btn--1">
            Human Resources
          </a>
        </div>
        <div className="form__group company__btn">
          <a href="mailto:it@mysharedservices.com.au" className="btn btn--1">
            Information Technology
          </a>
        </div>
        <div className="form__group company__btn">
          <a
            href="mailto:marketing@mysharedservices.com.au"
            className="btn btn--1"
          >
            Marketing
          </a>
        </div>
        <div className="form__group company__btn">
          <a
            href="mailto:property@mysharedservices.com.au"
            className="btn btn--1"
          >
            Property
          </a>
        </div>
        <div
          style={{ textAlign: "center" }}
          className="form__group company__btn"
        >
          <Link to={"/"} className="btn btn--1 btn--back">
            <span className="placeholder">{this.props.company.btnText}</span>
            <span className="back">Back</span>
          </Link>
        </div>
      </div>
    );
  };

  renderDefaultBtns = () => {
    return (
      <div className="company__btns">
        <div
          style={{ textAlign: "center" }}
          className="form__group company__btn"
        >
          <a
            href={this.props.company.link}
            target="_blank"
            className="btn btn--1"
            rel="noopener noreferrer"
          >
            {this.props.company.btnText}
          </a>
        </div>
        <div
          style={{ textAlign: "center" }}
          className="form__group company__btn"
        >
          <Link to={"/"} className="btn btn--1 btn--back">
            <span className="placeholder">{this.props.company.btnText}</span>
            <span className="back">Back</span>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.company) {
      return <div>Loading...</div>;
    }
    return (
      <div className={"company " + this.props.company.name}>
        <div className="container">
          <div className="company__logo">
            <img
              src={this.props.company.mainLogo}
              alt={`${this.props.company.name} Logo`}
            />
          </div>
          <div className="company__heading">{this.props.company.heading}</div>
          <div className="company__description">
            {this.props.company.description.map((des) => (
              <p key={des}>{des}</p>
            ))}
          </div>
                { 1 == 2 &&     // here to comment out the divs you dont want to use
          <div className="company__brands">
            {this.props.company.brands.length > 0 ? (
              <div className="company__brands--text">Our brands:</div>
            ) : (
              <div></div>
            )}
            <div className="company__brands--logos">
              {this.props.company.brands.map((b) => (
                <img
                  key={b}
                  className="company__brands--logo"
                  src={b}
                  alt={`${b} Logo`}
                />
              ))}
            </div>
                    </div>    // here to comment out the divs you dont want to use
                }              
          {this.props.company.name === "MySharedServices"
            ? this.renderMSSBtns()
            : this.renderDefaultBtns()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { company: state.companies[ownProps.match.params.name] };
};

export default connect(mapStateToProps, { fetchCompany })(CompanyPage);
