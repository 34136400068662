import React from "react";

const OurBlogHeader = () => {
  return (
    <div className="header__our-blog">
      <div className="header__our-blog__title">Our Blog</div>
      <div className="header__our-blog__sub-title">
        Please select an option to view our blog
      </div>
    </div>
  );
};

export default OurBlogHeader;
