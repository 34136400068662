import React from "react";

const AboutUsHeader = (props) => {
  return (
    <div className="header__about-us">
      <div className="header__about-us__title">
              <h1 className="heading heading--1">About Us</h1>
      </div>
      <p className="paragraph paragraph--1">
        We Innovate, Develop and Deliver solutions
        <br></br>
        &nbsp;to all of our customers within every unique vertical market
        <br></br>
        in which we operate
      </p>
    </div>
  );
};

export default AboutUsHeader;
