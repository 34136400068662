import React, { Component } from "react";
import * as blogAPI from "../../services/BlogService";

class OurBlogPage extends Component {
  componentDidMount() {
    document.title = "LeasePLUS Group - Our Blog";
  }
  render() {
    return (
      <div className="our-blog-page">
        <div className="blog-list">
          {blogAPI.getBlogs().map(blog => (
            <a
              href={blog.link}
              target="_blank"
              rel="noopener noreferrer"
              className="blog"
            >
              <div className="blog__container">
                <img src={blog.image} alt={blog.title} className="blog__img" />
                <h1 className="blog__title">{blog.title}</h1>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default OurBlogPage;
